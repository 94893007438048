import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import humanFemale from "./imgs/default female.jpg"
import repMale from "./imgs/Rep male.jpg"
import robotron from "./imgs/robotron.jpg"
import minion from "./imgs/minion.png"
import tool1 from "./imgs/tool1.png"
import tool2 from "./imgs/tool2.png"

import {
  Avatar,
  Button,
  Card,
  Icon,
  Line,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
// } from './components'
} from '@alien-worlds/react-components'

const Layout = () => {
  let cardDummy = [
    {
      variant: "avatar",
      type: "Human",
      imageUrl: humanFemale,
      title: "Female Human",
      description: "Adventure is worthwhile in itself",
      status: "active",
    },
    {
      variant: "tool",
      imageUrl: tool1,
      title: "Standart Shovel",
      status: "equipped",
      energy: "80",
      clover: "0.5",
      pyramid: "1",
      pickaxe: "1.4",
    },
    {
      variant: "tool",
      imageUrl: tool2,
      title: "Large Explosive",
      energy: "2999",
      clover: "1",
      pyramid: "0",
      pickaxe: "32",
    },
    {
      variant: "minion",
      imageUrl: minion,
      title: "Storm Giant",
      description:
        "Slow and powerful, Storm Giants can have a devast Slow and powerful, Storm Giants can have a devast",
      offense: "60",
      defense: "17",
      speed: "70",
    },
    {
      variant: "avatar",
      type: "Robotron",
      imageUrl: robotron,
      title: "Robotron Soldier",
      description:
        "Originally created by Greys, Sentineent Robotrons have developed their own Slow and powerful, Storm Giants can have a devast",
    },
    {
      variant: "avatar",
      type: "Reptiloid",
      imageUrl: repMale,
      title: "Male Reptiloid",
      description: "Greys and Reptiloids particularly dislike each other",
    },
    {
      variant: "tool",
      imageUrl: "",
      title: "Causian Atractor",
      energy: "80",
      clover: "0.5",
      pyramid: "1",
      pickaxe: "1.4",
    },
    {
      variant: "tool",
      imageUrl: "",
      title: "Processing Ring",
      energy: "349",
      clover: "8",
      pyramid: "3",
      pickaxe: "1",
    },
  ];
  const [cardInfos, setCardInfos] = useState(cardDummy);

  const { search } = useLocation();

  const currentTab = useMemo(() => {
    const queries = new URLSearchParams(search);
    return queries.get("tab");
  }, [search]);

  useEffect(() => {
/*     if (currentTab !== "All") {
      const structuredCurrentTab = currentTab.slice(0, -1).toLowerCase();
      setCardInfos(
        cardDummy.filter((item) => item.variant === structuredCurrentTab)
      );
    } else setCardInfos(cardDummy); */
    if (currentTab === "Weapons")
    setCardInfos(cardDummy.filter((item) => item.variant === "weapon"));
  else if (currentTab === "Avatars")
    setCardInfos(cardDummy.filter((item) => item.variant === "avatar"));
  else if (currentTab === "Minions")
    setCardInfos(cardDummy.filter((item) => item.variant === "minion"));
  else if (currentTab === "Tools")
    setCardInfos(cardDummy.filter((item) => item.variant === "tool"));
  else setCardInfos(cardDummy);
  }, [currentTab]);

  return (
    <div class="md:flex flex-col md:flex-row md:min-h-screen w-full">
      <div
        className="flex flex-col w-full md:w-64 bg-white flex-shrink-0"
        style={{ background: "#1D1838" }}
      >
        <a
          href="#"
          className="px-8 py-4 text-lg font-semibold tracking-widest uppercase rounded-lg focus:outline-none focus:shadow-outline text-white"
        >
          Alien Worlds
        </a>
        <Line />

        <div className="md:flex flex-col items-center justify-center h-56 mt-9">
          <Avatar imageUrl={humanFemale}/>
          <span className="mt-3.5 text-xl font-bold text-white font-orbitron">
            NoHod297
          </span>
          <span className="mb-8 text-secondary-lightest text-sm leading-5 font-normal font-spaceGrotesk">
            5kxb4.wan
          </span>
        </div>
        <Line />
        <div className="mt-10">
          <Menu>
            <MenuItem to="/" icon={<Icon name="home" />}>
              Home
            </MenuItem>
            <MenuItem to="/inventory" icon={<Icon name="folder_check" />}>
              Inventory
            </MenuItem>
            <MenuItem to="/staking" icon={<Icon name="tool" />}>
              Staking
            </MenuItem>
            <MenuItem
              to="/settings"
              icon={<Icon name="setting" />}
              label="Settings"
              badge="7"
            />
            <div className="mt-96 pb-5">
              <div className="pb-3">
                <Line />
              </div>

              <MenuItem to="/logout" icon={<Icon name="exit" />}>
                Log Out
              </MenuItem>
            </div>
          </Menu>
        </div>
      </div>

      <div
        className="md:flex flex-col md:flex-row md:min-h-screen w-full p-20"
        style={{
          background: "linear-gradient(183.18deg, #540D40 0%, #1C1D3B 99.03%)",
        }}
      >
        <div className="md:flex flex-col w-full">
          <div className="md:flex flex-row items-center justify-between w-full">
            <Typography variant="h1" component="h1" color="white">
              Inventory
            </Typography>
            <Button size="large" color="primary" startIcon="cart">
              BUY NFT'S
            </Button>
          </div>
          <div className="md:flex flex-row items-center justify-between w-full">
            <Tabs>
              <Tab>All</Tab>  
              <Tab>Weapons</Tab>  
              <Tab label="Avatars" />
              <Tab label="Minions" />
              <Tab label="Tools" />
            </Tabs>
            <Button
              color="secondary"
              size="small"
              startIcon="sort_down"
            >
              Name
            </Button>
          </div>
          <div className="cards w-full mt-10 mx-auto">
            <div className="flex flex-wrap">
              {cardInfos.map((item) => (
                <Card
                  variant={item.variant}
                  type={item.type}
                  imageUrl={item.imageUrl}
                  title={item.title}
                  description={item.description}
                  status={item.status}
                  offense={item.offense}
                  defense={item.defense}
                  speed={item.speed}
                  energy={item.energy}
                  clover={item.clover}
                  pyramid={item.pyramid}
                  pickaxe={item.pickaxe}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
